/*
 * Composant génrique d'action d'une grille (filters, current page, sorts, etc).
 * 
 * Pour fonctionner, mettre un id de grid unique | Ajouter l'event onDataBound sur la Grid Kendo 
 */

import toastr from 'toastr';
import swal from 'sweetalert2'

export default class GridComponent {

    constructor($view) {

        this.$view = $view;
        this.SessionStorageKey = "GridOption";
        this.SaveFilter = this.$view.data('save-filter');
        this.DisableSetGridOptions = this.$view.data('disable-setgridoptions');
        this.IsTreeList = false;
        this.Init();
    }

    Init() {
        let self = this;
        console.log('init grid component');

        //Si on se déonnecte, on supprime la session
        $(".btnDeconnection").on("click", function () {
            sessionStorage.clear();
        })

        //Si on change de user via la fonction LogAs en SuperAdmin, on réinitialise les filtres
        this.$view.on("click", '.btConnectTo', (e) => {
            sessionStorage.clear();
        });

        // Pour sauvegarder le type de grille et faire différer certain comporttement quand on est sur une treelist
        if (this.$view.data('istreelist') === true) {
            self.IsTreeList = true;
        }


        // Uniquement pour les treeList
        if (self.IsTreeList) {
            $("#expand-treelist").on("click", function () {
                self.ExpandTreelist(self);
            });
        }

        window.onDataBound = this.window_onDataBound;
        window.setGridOptions = this.window_setGridOptions;
        window.GetGridFilterValue = this.window_GetGridFilterValue;
        window.AddOrRemoveFilter = this.window_AddOrRemoveFilter;
        window.FirstDataBound = false;


        //Popup de confirmation de suppression pour les grid inline
        this.$view.on("click", '.ConfirmInlineDeletePopup', (e) => {

            // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
            e.preventDefault(); //ne fonctionne pas :/

            //On récupère de façon générique la Grid Kendo de la page
            var $grid = null;

            if (self.IsTreeList) {
                //On récupère de façon générique la Grid Kendo de la page
                $grid = $("div[data-role='treelist']");
            } else {
                //On récupère de façon générique la Grid Kendo de la page
                $grid = $("div[data-role='grid']");
            }

            var kendoGrid = $grid.data("kendoGrid");
            var modelToDelete = kendoGrid.dataItem($(e.target).parents('tr'));

            // On ouvre une popup de confirmation
            swal.fire({
                title: "Suppression",
                text: "Voulez vous supprimer ? Cette action sera irréversible",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#333333",
                cancelButtonColor: "#c1c1c1",
                confirmButtonText: "Confirmer",
                cancelButtonText: "Annuler",
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    kendoGrid.dataSource.remove(modelToDelete);
                    kendoGrid.dataSource.sync();
                }
            })
            //kendo.confirm("Confirmer la suppression ?").then(function () {
            //    // COnfirm
            //    kendoGrid.dataSource.remove(modelToDelete);
            //    kendoGrid.dataSource.sync();
            //}, function () {
            //    // Denied
            //});
        });


        //Popup de confirmation d'action
        this.$view.on("click", '.ConfirmPopup', (e) => {

            // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
            e.preventDefault(); //ne fonctionne pas :/

            //On récupère de façon générique la Grid Kendo de la page
            var grid = null;

            if (self.IsTreeList) {
                //On récupère de façon générique la Grid Kendo de la page
                grid = $("div[data-role='treelist']")[0];
            } else {
                //On récupère de façon générique la Grid Kendo de la page
                grid = $("div[data-role='grid']")[0];
            }

            var gridName = grid.id;

            // On récupère les data apposées sur le boutons
            let data = e.currentTarget.dataset;

            swal.fire({
                title: "Suppression",
                text: "Voulez vous supprimer ? Cette action sera irréversible",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#333333",
                cancelButtonColor: "#c1c1c1",
                confirmButtonText: "Confirmer",
                cancelButtonText: "Annuler",
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    $.ajax({
                        type: 'POST',
                        url: data.url,
                        success: function (data) {
                            if (self.IsTreeList) {
                                $("#" + gridName).data("kendoTreeList").dataSource.read();
                            } else {
                                $("#" + gridName).data("kendoGrid").dataSource.read();
                            }
                            toastr.success("Suppression effectuée !");
                        },
                        error: function (data) {
                            // Le but est de n'afficher que les messages d'erreur provenant d'un return BadRequest()
                            if (data.responseText && data.status == 400) {
                                toastr.error(data.responseText);
                            } else {
                                toastr.error("Une erreur est survenue");
                            }
                        }
                    });
                }
            })

            //// On ouvre une popup de confirmation
            //kendo.confirm("Confirmer la suppression ?").then(function () {

            //    // En cas de oui on call l'url
            //    $.ajax({
            //        type: 'POST',
            //        url: data.url,
            //        success: function (data) {
            //            if (self.IsTreeList) {
            //                $("#" + gridName).data("kendoTreeList").dataSource.read();
            //            } else {
            //                $("#" + gridName).data("kendoGrid").dataSource.read();
            //            }
            //            toastr.success("Suppression éffectuée !");
            //        },
            //        error: function (data) {
            //            // Le but est de n'afficher que les messages d'erreur provenant d'un return BadRequest()
            //            if (data.responseText && data.status == 400) {
            //                toastr.error(data.responseText);
            //            } else {
            //                toastr.error("Une erreur est survenue");
            //            }
            //        }
            //    });
            //}, function () {
            //    // Denied
            //    console.log("Suppression canceled");
            //});

        });

        if (this.DisableSetGridOptions && this.DisableSetGridOptions !== true) {
            $(function () {

                var grid = null;

                if (self.IsTreeList) {
                    //On récupère de façon générique la Grid Kendo de la page
                    grid = $("div[data-role='treelist']")[0];
                } else {
                    //On récupère de façon générique la Grid Kendo de la page
                    grid = $("div[data-role='grid']")[0];
                }

                var gridName = grid.id;
                // Récupération de l'état de la Grid via le localStorage (filters, current page, sorts, etc).
                self.window_setGridOptions(gridName, self.IsTreeList);
            });
        }

    }

    // On affiche un toast en cas d'erreur de la grille
    window_ErrorHandler(e) {

        debugger;

        if (e.xhr.responseText) {
            console.log(e.xhr.responseText);
        }

        toastr.options = {
            timeOut: 0,
            extendedTimeOut: 0
        };

        toastr.error("Une erreur est survenue");
    }

    window_onDataBound(e) {
        // Afin de définir si on est sur la premier databound 
        if (window.FirstDataBound === false) {

            // Afin que ça n'arrive que sur le premier
            window.FirstDataBound = true;

            // On check si la méthode existe sur la page
            var fn = window["FirstDataBoundMethod"];

            // Et que c'est bien une fonction
            if (typeof fn === 'function') {
                // On éxécute la méthode
                window.FirstDataBoundMethod();
            }
        }

        var parent = e.sender.element[0].parentElement;
        var saveFilter = $(parent).data('save-filter');

        //On récupère le nom de la Grid Kendo courrante
        var gridName = e.sender.element[0].id;

        //On récupère le type Grille ou TreeList
        var type = $(e.sender.element[0]).data("role");

        var grid = null;

        if (type === "treelist") {
            //On récupère la Grid Kendo courrante
            grid = $("#" + gridName).data("kendoTreeList");
        } else {
            //On récupère la Grid Kendo courrante
            grid = $("#" + gridName).data("kendoGrid");
        }

        if (saveFilter === true) {
            //On stocke le state de la grid Kendo dans le sessionStorage (détruit à la fin d'une session de navigation et limité à l'onglet actif ) 
            //console.log(sessionStorage[gridName + "GridOption"]);
            sessionStorage[gridName + "GridOption"] = kendo.stringify(grid.getOptions());
        }
        else {
            var Options = grid.getOptions();
            delete Options.dataSource.columns;
            delete Options.columns;
            sessionStorage[gridName + "GridOption"] = kendo.stringify(Options);
        }
    }

    window_setGridOptions(gridName, isTreeList) {
        //On récupère le state de la Grid Kendo depuis le localStorage
        var options = sessionStorage[gridName + this.SessionStorageKey];
        if (options) {
            if (isTreeList) {
                $("#" + gridName).data("kendoTreeList").setOptions(JSON.parse(options));
            } else {
                $("#" + gridName).data("kendoGrid").setOptions(JSON.parse(options));
            }
        }
    }

    // Permet de récupérer la valeur d'un filtre si il existe sinon renvoi nul
    window_GetGridFilterValue(grid, field) {
        var dataSource = grid.dataSource;
        var filters = null;
        if (dataSource.filter() != null) {
            filters = dataSource.filter().filters;
        }

        // On test d'abord la présence de filtre
        if (filters) {
            // On parcours le premier lvl de filtre
            for (var x = 0; x < filters.length; x++) {
                var temp = filters[x];
                if (temp.field == field) {
                    return temp.value;
                } else if (temp.filters) {
                    // Sinon on check si il n'y pas de deuxième lvl de filtre
                    for (var y = 0; y < temp.filters.length; y++) {
                        temp2 = temp.filters[y];
                        if (temp2.field == field) {
                            return temp2.value;
                        }
                    }
                }
            }
        }
        return null;
    }


    //Permet de refresh des données la grid
    RefreshGrid() {
        let self = this;

        var grid = null;

        if (self.IsTreeList) {
            //On récupère de façon générique la Grid Kendo de la page
            grid = $("div[data-role='treelist']")[0];
        } else {
            //On récupère de façon générique la Grid Kendo de la page
            grid = $("div[data-role='grid']")[0];
        }

        var gridName = grid.id;

        grid = $("#" + gridName).data("kendoGrid");
        grid.dataSource.read();
        grid.refresh();
    }

    window_AddOrRemoveFilter(grid, field, operator, value) {

        var newFilter = { field: field, operator: operator, value: value };
        var dataSource = grid.dataSource;
        var filters = null;
        if (dataSource.filter() != null) {
            filters = dataSource.filter().filters;
        }

        if (value && value.length > 0) {
            //Add filter
            if (filters == null) {
                filters = [newFilter];
            }
            else {
                var isNew = true;
                var index = 0;
                for (index = 0; index < filters.length; index++) {
                    if (filters[index].field == field) {
                        isNew = false;
                        break;
                    }
                }
                if (isNew) {
                    filters.push(newFilter);
                }
                else {
                    filters[index] = newFilter;
                }
            }
        }
        else {
            //Remove filter
            var removeIndex = -1;
            var removeIndex2 = -1;
            for (var x = 0; x < filters.length; x++) {
                var temp = filters[x];
                if (temp.field == field) {
                    removeIndex = x;
                    break;
                } else if (temp.filters) {
                    for (var y = 0; y < temp.filters.length; y++) {
                        temp2 = temp.filters[y];
                        if (temp2.field == field) {
                            removeIndex2 = y;
                            break;
                        }
                    }
                    if (removeIndex2 != -1)
                        temp.filters.splice(removeIndex2, 1);
                }
            }
            if (removeIndex != -1)
                filters.splice(removeIndex, 1);

        }
        dataSource.filter(filters);
    }

    ExpandTreelist(self) {

        //On récupère de façon générique la Grid Kendo de la page
        var treeList = $("div[data-role='treelist']", self.$view).data("kendoTreeList");

        var Options = treeList.getOptions();

        // En fonction de l'état actuel on va collapse ou expand
        Options.dataSource.schema.model.expanded = !Options.dataSource.schema.model.expanded
        treeList.setOptions(Options);

        // on change la visibilité de l'icone
        if (!Options.dataSource.schema.model.expanded) {
            $("#expand-treelist .fa-toggle-off").show();
            $("#expand-treelist .fa-toggle-on").hide();
        } else {
            $("#expand-treelist .fa-toggle-off").hide();
            $("#expand-treelist .fa-toggle-on").show();
        }
    }

    simpleStringify(object) {
        // stringify an object, avoiding circular structures
        // https://stackoverflow.com/a/31557814
        var simpleObject = {};
        for (var prop in object) {
            if (!object.hasOwnProperty(prop)) {
                continue;
            }
            if (typeof (object[prop]) == 'object') {
                continue;
            }
            if (typeof (object[prop]) == 'function') {
                continue;
            }
            simpleObject[prop] = object[prop];
        }
        return JSON.stringify(simpleObject); // returns cleaned up JSON
    };
}