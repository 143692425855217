//import toastr from 'toastr';

class AppError {
    constructor() {
        this.Init();
    }

    Init() {
        console.log('AppError.js');
    }

    // Afin de logger les erreurs dans les grids
    GridErrorHandler(e) {

        //if (e.xhr.responseText) {
        //    console.log(e.xhr.responseText);
        //}

        toastr.options = {
            timeOut: 0,
            extendedTimeOut: 0
        };

        toastr.error("Une erreur est survenue");
    }
}

module.exports = AppError;