class Modal {
    constructor() {
        this.Init();
        this.Modals = new Object();
        this.Modals = {};
    }

    Init() {
        console.log('modal.js');
    }

    // Permet de récupérer une modale
    GetModal(id, title, height, width) {
        var thisObj = this;

        var window = $("#" + id);

        window.kendoWindow({
            title: title,
            draggable: false,
            modal: true,
            width: width,
            height: height,
            refresh: function () {
                this.center();
            },
            actions: [
                "Close"
            ]
        });

        var modal = window.data("kendoWindow");

        thisObj.Modals[id] = modal;

        return modal;
    }

    // Permet charger une modale depuis une url
    LoadModal(id, url, data, title, height, width, callback, content) {
        var thisObj = this;

        var $window = $("#" + id);

        $window.kendoWindow({
            title: title,
            draggable: true,
            modal: true,
            width: width,
            position: { top: 100 },
            height: height,
            visible:false,
            refresh: function () {
                this.center();
            },
            actions: [
                "Close"
            ]
        });

        var modal = $window.data("kendoWindow");

        thisObj.Modals[id] = modal;

        if (url) {
            //On charge la modale depuis l'url fourni
            $.ajax({
                url: url,
                type: 'POST',
                data: data,
                success: function (data) {
                    //On charge le contenu de la modal
                    modal.content(data);
                    modal.center();
                    modal.open();

                    // Pour activer la validation jquery sur les composant Kendo
                    $.validator.setDefaults({
                        ignore: ""
                    });
                    // On set la jquery validate pour le contenu généré
                    $.validator.unobtrusive.parse($("#" + id));

                    if (typeof callback !== "undefined" && callback !== null) {
                        callback();
                    }
                }
            });
        } else if (content) {
            // on lui passe directement un contenu
            modal.content(content);
            modal.center();
            modal.open();

            // On va la center vers le haut
            $window.kendoWindow({
                position: {
                    top: "10%"
                }
            });
        }

        return modal;
    }
}

module.exports = Modal;