
export default class AppComponent {
    constructor($view) {
        this.view = $view;
        console.log('AppComponent');
        this.SuccessToaster = this.view.data("success-toaster");
        this.FailToaster = this.view.data("fail-toaster");
        this.CurrentController = this.view.data("current-controller");
        this.CurrentArea = this.view.data("current-area");
        this.Init();
    }

    Init() {
        var self = this;

        // Gestion des toast
        if (self.SuccessToaster === 'True') {
            toastr.success("Opération effectuée avec succès");
        }

        if (self.FailToaster === 'True') {
            toastr.error("Une erreur est survenue");
        }

        // Afin de enligh le bon menu
        if (self.CurrentController !== '') {
            // On laisse le plugin gérer son ses menu
            if (self.CurrentArea !== 'Editorial') {
                $(".app-aside-body a").removeClass('active');
                $(".app-aside-body #" + self.CurrentController + "Menu").addClass('active')
            }
        }


        //Gestion des boutons de confirmations
        $('.confirmAction', self.view).on("click", function (e) {

            // Afin d'empécher le click sur le bouton de nous faire remonter en haut de page
            e.preventDefault(); //ne fonctionne pas :/

            // On récupère les data apposées sur le boutons
            let data = e.currentTarget.dataset;

            let type = "POST";
            if (data.type === "GET" || data.type === "POST") {
                type = data.type;
            }

            // On ouvre une popup de confirmation
            kendo.confirm(data.modalMsg).then(function () {

                // En cas de oui on call l'url
                $.ajax({
                    type: type,
                    url: data.url,
                    success: function (retour) {
                        // TODO voir ce qu'on fait
                        if (data.successRedirectUrl) {
                            window.location.href = data.successRedirectUrl;
                        } else {
                            toastr.success("Opération effectuée avec succès");
                        }
                    },
                    error: function (retour) {
                        toastr.error("Une erreur est survenue");
                    }
                });
            }, function () {
                // Denied
                console.log("L'action a été annulée");
            });
        });
    }
}