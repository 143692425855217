import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import toastr from 'toastr';
window.toastr = toastr;

import swal from 'sweetalert2';
window.swal = swal;

import "6tm-admin-theme/src/js/main";

import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.editor';
import '@progress/kendo-ui/js/kendo.filtercell';
import '@progress/kendo-ui/js/kendo.aspnetmvc';
import '@progress/kendo-ui/js/kendo.treelist';

import '@progress/kendo-ui/js/cultures/kendo.culture.fr.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js';

// Pour le Kernel et le data-mount
import App from '6tm-components';
window.App = App;

import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';


// Components via data-mount
import TabComponent from './js/components/TabComponent';
import GridComponent from './js/components/GridComponent';
import AppComponent from './js/components/AppComponent';

App.Kernel.registerComponent('TabComponent', TabComponent)
App.Kernel.registerComponent('GridComponent', GridComponent)
App.Kernel.registerComponent('AppComponent', AppComponent)

// Global components
const Modal = require('./js/Modal');
window.Modal = new Modal();

const AppError = require('./js/AppError');
window.AppError = new AppError();

$(() => {
    App.Kernel.bindComponents($("body"));
});




import './scss/main.scss';